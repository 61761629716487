import * as React from "react"
import { Helmet } from "react-helmet"

import { graphql, PageProps } from "gatsby"
import { GatsbyImage, IGatsbyImageData, StaticImage } from "gatsby-plugin-image"

import DownloadSection from "../components/download"
import Layout from "../components/layouts"
import Seo from "../components/seo"

import { LANG_IT } from "../utils/localization"
import { SiteInformation } from "../utils/site"

type Props = SiteInformation & {
  allFile: {
    edges: [
      {
        node: {
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData
            resize: {
              src: string
              originalName: string
            }
          }
        }
      }
    ]
  }
}

const IndexPage: React.FC<PageProps<Props>> = ({ data,path }) => (
  <Layout lang={LANG_IT}>
    <Helmet>
      <link
        rel="alternate"
        hrefLang={"x-default"}
        href={`${data.site.siteMetadata.siteUrl}/`}
      />
      <link
        rel="alternate"
        hrefLang={LANG_IT}
        href={`${data.site.siteMetadata.siteUrl}/it/`}
      />
    </Helmet>
    <Seo
      title="Pagina Principale"
      lang={LANG_IT}
      description="Doodle+ è una fantastica app di disegno che riporta la gioia di dipingere nel tuo tempo libero, da solo o con amici."
      path={path}
    />
    <section id="hero">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
            <StaticImage
              src="../images/iphone.png"
              width={450}
              placeholder="none"
              className="img-fluid"
              alt="App Homepage"
            />
          </div>
          <div className="col-md-7 content-box hero-content">
            <span>{data.site.siteMetadata.title}</span>
            <h1>
              Più creativo stai cercando di essere, <b>più creativo sarai!</b>
            </h1>
            <p>
              Doodle+ è una fantastica app di disegno che riporta la gioia di
              dipingere nel tuo tempo libero, indipendentemente dal fatto che tu
              voglia farlo da solo e goderti il divertimento di disegnare con i
              tuoi amici.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="marketing">
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="content-box">
              <span>Caratteristiche</span>
              <h2>Collaborazione</h2>
              <p>
                Puoi condividere il codice univoco e iniziare a collaborare al
                tuo disegno con gli amici in tempo reale. Non c'è limite a ciò
                che puoi realizzare, è incredibile vedere le tue opere d'arte
                create come per magia.
              </p>
              &nbsp;
              <ul>
                <li>
                  <i className="icon ion-md-checkmark-circle-outline" />
                  Interfaccia intuitiva per scegliere il colore e la dimensione
                  del pennello
                </li>
                <li>
                  <i className="icon ion-md-checkmark-circle-outline" /> Non è
                  richiesta alcuna registrazione, basta scaricare l'app e
                  iniziare a disegnare
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-7">
            <div className="content-box photo-gallery">
              <div className="row photos">
                {data.allFile.edges.map(({ node }) => (
                  <div
                    key={node.childImageSharp.resize.originalName}
                    className="col-sm-3 col-md-4 col-lg-4 item text-center"
                  >
                    <a
                      href={node.childImageSharp.resize.src}
                      data-lightbox="screenshots"
                    >
                      <GatsbyImage
                        image={node.childImageSharp.gatsbyImageData}
                        alt={node.childImageSharp.resize.originalName}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <DownloadSection lang={LANG_IT} />
  </Layout>
)

export default IndexPage

export const query = graphql`
  query {
    site {
      ...SiteInformation
    }
    allFile(
      filter: { sourceInstanceName: { eq: "screenshots" } }
      sort: { fields: childImageSharp___resize___originalName }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(width: 150)
            resize(width: 500) {
              src
              originalName
            }
          }
        }
      }
    }
  }
`
